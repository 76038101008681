"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const ipv4_calc_1 = require("ipv4-calc");
const ipaddr = require('ipaddr.js');
const bitOR = function (a, b) {
    while (b.length < a.length) {
        b = '0' + b;
    }
    return [...a].map((item, index) => {
        if ((item == '1') || (b[index] == '1')) {
            return '1';
        }
        else {
            return item;
        }
    }).join('');
};
const parseHex = function (a) {
    if (a != undefined) {
        return parseInt(a.toString(), 16);
    }
    return 0;
};
const parseBin = function (a) {
    if (a != undefined) {
        return parseInt(a.toString(), 2);
    }
    return 0;
};
const arrayToIpv6 = function (a) {
    var _a;
    return a ? (_a = a.join('').match(/\w{4}/g)) === null || _a === void 0 ? void 0 : _a.join(':') : undefined;
};
const bitSubHexPart = function (a, b) {
    // a = ffff, b = 012d
    return [...a].map((item, index) => (parseHex(item) - parseHex(b[index])).toString(16)).join('');
};
const binToHex = function (a) {
    var _a;
    return (_a = parseBin(a)) === null || _a === void 0 ? void 0 : _a.toString(16);
};
const hexToBin = function (a) {
    return parseHex(a).toString(2);
};
const bitHexToBinary = function (a) {
    return [...a].map((item) => bitOR('0000', hexToBin(item))).join('');
};
const exponentialToDecimal = (exponential) => {
    let decimal = exponential.toString().toLowerCase();
    if (decimal.includes('e+')) {
        const exponentialSplitted = decimal.split('e+');
        let postfix = '';
        for (let i = 0; i <
            +exponentialSplitted[1] -
                (exponentialSplitted[0].includes('.') ? exponentialSplitted[0].split('.')[1].length : 0); i++) {
            postfix += '0';
        }
        const addCommas = (text) => {
            let j = 3;
            let textLength = text.length;
            while (j < textLength) {
                text = `${text.slice(0, textLength - j)},${text.slice(textLength - j, textLength)}`;
                textLength++;
                j += 3 + 1;
            }
            return text;
        };
        decimal = addCommas(exponentialSplitted[0].replace('.', '') + postfix);
    }
    if (decimal.toLowerCase().includes('e-')) {
        const exponentialSplitted = decimal.split('e-');
        let prefix = '0.';
        for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
            prefix += '0';
        }
        decimal = prefix + exponentialSplitted[0].replace('.', '');
    }
    return decimal;
};
class SubnetcalculatorCtrl {
    constructor($scope, $timeout, ConfigService, $filter) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ConfigService = ConfigService;
        this.$filter = $filter;
        this.kind = 'ipv4';
        this.ipv4Pattern = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
        this.ipv6Pattern = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/;
        this.ipv4Subnets = [...Array(32)].map((item, index) => {
            const binary = (Math.pow(2, 32) - (Math.pow(2, index))).toString(2);
            const parts = binary.match(/\d{8}/g) || [];
            const mask = parts.map((item) => parseInt(item, 2)).join('.');
            const cidr = 32 - index;
            return {
                cidr: cidr,
                binaryMask: parts.join('.'),
                mask: mask,
                label: `${mask} /${cidr}`
            };
        }).reverse();
        this.ipv6Prefixes = [...Array(128).keys()].map((item, index) => index + 1);
        this.ipv4Classes = [
            {
                label: this.$filter('translate')('Any'),
                value: undefined,
                minCidr: 1,
                maxCidr: 32,
            },
            {
                label: 'A',
                value: 'A',
                minCidr: 1,
                maxCidr: 8,
            },
            {
                label: 'B',
                value: 'B',
                minCidr: 8,
                maxCidr: 16,
            },
            {
                label: 'C',
                value: 'C',
                minCidr: 16,
                maxCidr: 32,
            },
        ];
        this.defaultData = {
            // ipv4: undefined,
            ipv4: undefined,
            ipv4Class: this.ipv4Classes[0],
            ipv4Subnet: this.ipv4Subnets[31],
            // ipv6: '2001:db8:85a3::8a2e:370:7334',
            ipv6: undefined,
            ipv6Prefix: 64,
        };
    }
    $onInit() {
        var _a, _b, _c, _d, _e;
        if ((_a = this.config) === null || _a === void 0 ? void 0 : _a.client_remote_ip) {
            if (((_c = (_b = this.config) === null || _b === void 0 ? void 0 : _b.client_remote_ip) === null || _c === void 0 ? void 0 : _c.indexOf(':')) > -1) {
                this.client_remote_ipv6 = (_d = this.config) === null || _d === void 0 ? void 0 : _d.client_remote_ip;
                this.client_remote_ipv4 = '127.0.0.1';
            }
            else {
                this.client_remote_ipv4 = (_e = this.config) === null || _e === void 0 ? void 0 : _e.client_remote_ip;
                this.client_remote_ipv6 = '2001:8a24:7d:28:15:18f:22:23';
            }
        }
        // this.defaultData.ipv4 = '37.48.76.147'
        // this.defaultData.ipv4Subnet = this.ipv4Subnets[26]
        this.data = ng.copy(this.defaultData);
    }
    setKind(kind) {
        this.kind = kind;
        this.result = undefined;
        this.error = undefined;
    }
    setIpv4Class(item) {
        this.data.ipv4Class = item;
        if ((this.data.ipv4Subnet.cidr < item.minCidr) || (this.data.ipv4Subnet.cidr > item.maxCidr)) {
            this.data.ipv4Subnet = this.ipv4Subnets.filter((subnet) => {
                return (subnet.cidr >= item.minCidr) && (subnet.cidr <= item.maxCidr);
            }).reverse()[0] || this.data.ipv4Subnet;
        }
    }
    setIpv4Subnet(item) {
        var _a;
        this.data.ipv4Subnet = item;
        (_a = this.subnetForm) === null || _a === void 0 ? void 0 : _a.$setDirty();
    }
    submit() {
        var _a, _b, _c, _d, _e, _f;
        if (this.subnetForm && this.subnetForm.$valid) {
            this.error = undefined;
            if (this.kind == 'ipv4') {
                const short = `${this.data.ipv4}/${this.data.ipv4Subnet.cidr}`;
                const ip = new ipv4_calc_1.IPv4(short);
                const networkInfo = ip.getNetworkInfo();
                const subnetsInfo = ip.getSubNetworksInfo();
                const ipv4BinaryParts = this.data.ipv4.split('.').map((item) => {
                    const z = (Math.pow(2, 8) + parseInt(item)).toString(2);
                    return z.slice(1, z.length);
                });
                const ipv6 = (this.data.ipv4.split('.').map((item) => {
                    let hex = parseInt(item).toString(16);
                    if (hex.length < 2) {
                        hex = '0' + hex;
                    }
                    return hex;
                }).join('').match(/\w{4}/g) || []).join(':');
                const ipaddrParsed = ipaddr.parse(this.data.ipv4);
                const octets = ipaddrParsed.octets;
                let ipClass = 'A';
                if ((octets[0] >= 128) && (octets[0] <= 191) && (octets[1] >= 0) && (octets[1] <= 255)) {
                    ipClass = 'B';
                }
                if ((octets[0] >= 192) && (octets[0] <= 223) && (octets[1] >= 0) && (octets[1] <= 255) && (octets[2] >= 0) && (octets[2] <= 255)) {
                    ipClass = 'C';
                }
                if ((octets[0] >= 224) && (octets[0] <= 239)) {
                    ipClass = 'D';
                }
                if ((octets[0] >= 240) && (octets[0] <= 255)) {
                    ipClass = 'E';
                }
                const numberOfUsableHosts = this.data.ipv4Subnet.cidr >= 31 ? 0 : Math.pow(2, 32 - this.data.ipv4Subnet.cidr) - 2;
                this.result = {
                    ipv4: this.data.ipv4,
                    ipAddressBinary: ipv4BinaryParts.join('.'),
                    networkAddress: networkInfo.decNetwork,
                    // usableIpRange: numberOfUsableHosts ? `${networkInfo.decFirstAddress} - ${networkInfo.decLastAddress}` : null,
                    usableIpRangeMin: numberOfUsableHosts ? networkInfo.decFirstAddress : null,
                    usableIpRangeMax: numberOfUsableHosts ? networkInfo.decLastAddress : null,
                    broadcastAddress: networkInfo.decBroadcast,
                    totalNumberOfHosts: this.data.ipv4Subnet.cidr >= 31 ? 1 : Math.pow(2, 32 - this.data.ipv4Subnet.cidr),
                    numberOfUsableHosts: this.data.ipv4Subnet.cidr >= 31 ? 1 : numberOfUsableHosts,
                    subnetMask: this.data.ipv4Subnet.mask,
                    wildcardMask: networkInfo.decWildCardMask,
                    binarySubnetMask: this.data.ipv4Subnet.binaryMask,
                    ipClass: ipClass,
                    CIDR: this.data.ipv4Subnet.cidr,
                    ipType: ipaddrParsed.range(),
                    short: short,
                    binaryId: ipv4BinaryParts.join(''),
                    integerId: parseInt(ipv4BinaryParts.join(''), 2),
                    hexId: '0x' + parseInt(ipv4BinaryParts.join(''), 2).toString(16),
                    in_addrArpa: this.data.ipv4.split('.').reverse().join('.') + '.in-addr.arpa',
                    ipv4MappedAddress: '::ffff:' + this.data.ipv4,
                    _6to4Prefix: '2002:' + ipv6 + '::/48',
                    // subnets: subnetsInfo
                };
            }
            else if (this.kind == 'ipv6') {
                const ipaddrParsed = ipaddr.parse(this.data.ipv6);
                const total = Array(128).fill('0').join('');
                const bitParts = total.replace(new RegExp(`0{${128 - this.data.ipv6Prefix}}$`), Array(128 - this.data.ipv6Prefix).fill('1').join(''));
                const hexParts = (_a = bitParts.match(/\d{4}/g)) === null || _a === void 0 ? void 0 : _a.map((item) => binToHex(item));
                const fullIpAddress = ipaddrParsed.toFixedLengthString();
                const ipv6Binary = fullIpAddress.split(':').map((item) => bitHexToBinary(item)).join('');
                const maxPartsHex = (_c = (_b = bitOR(ipv6Binary, bitParts)) === null || _b === void 0 ? void 0 : _b.match(/\d{4}/g)) === null || _c === void 0 ? void 0 : _c.map((item) => binToHex(item));
                const minPartsHex = (_e = (_d = bitOR(ipv6Binary, bitParts)) === null || _d === void 0 ? void 0 : _d.match(/\d{4}/g)) === null || _e === void 0 ? void 0 : _e.map((item, index) => {
                    //@ts-ignore
                    return bitSubHexPart(binToHex(item), hexParts[index]);
                });
                this.result = {
                    ipv6: `${this.data.ipv6}`,
                    fullIpAddress: fullIpAddress,
                    totalIpAddresses: BigInt(Math.pow(2, (128 - this.data.ipv6Prefix))),
                    total64Networks: this.data.ipv6Prefix < 64 ? BigInt(Math.pow(2, (64 - this.data.ipv6Prefix))) : undefined,
                    network: minPartsHex ? ((_f = arrayToIpv6(minPartsHex)) === null || _f === void 0 ? void 0 : _f.split(':').filter((item, index) => {
                        var _a;
                        return ((_a = arrayToIpv6(maxPartsHex)) === null || _a === void 0 ? void 0 : _a.split(':')[index]) != 'ffff';
                    }).join(':')) + (this.data.ipv6Prefix <= 112 ? '::' : ':0000') : undefined,
                    // ipRange: `${arrayToIpv6(minPartsHex)}-${arrayToIpv6(maxPartsHex)}`,
                    ipRangeMin: arrayToIpv6(minPartsHex),
                    ipRangeMax: arrayToIpv6(maxPartsHex),
                };
            }
        }
    }
    clear() {
        if (this.subnetForm) {
            this.subnetForm.$setPristine();
            this.subnetForm.$setUntouched();
        }
        this.data = ng.copy(this.defaultData);
        this.result = undefined;
        this.error = undefined;
    }
    setIpv4(ip) {
        var _a;
        this.data.ipv4 = ip;
        (_a = this.subnetForm) === null || _a === void 0 ? void 0 : _a.$setDirty();
    }
    setIpv6(ip) {
        var _a;
        this.data.ipv6 = ip;
        (_a = this.subnetForm) === null || _a === void 0 ? void 0 : _a.$setDirty();
    }
}
SubnetcalculatorCtrl.$inject = ['$scope', '$timeout', 'ConfigService', '$filter'];
const appModule = ng.module('app');
appModule.component('gameSubnetcalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: SubnetcalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('subnetcalculator/');
    }]);
